import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "path": "/blog/architecting-skpr-mysql-service",
  "date": "1 November 2021",
  "title": "Architecting the Skpr MySQL Service",
  "summary": "This blog post covers the considerations that went into designing and developing the managed MySQL service built on top of Amazon RDS.",
  "author": "Nick Schuch",
  "tag": "Development",
  "tagColor": "blue",
  "tags": [{
    "name": "mysql"
  }, {
    "name": "service"
  }]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Skpr provides applications with a robust MySQL implementation built on the highly regarded Amazon Relational Database Service.`}</p>
    <p>{`This blog post covers the considerations that went into the design and development of the service.`}</p>
    <h3>{`Architecture Overview`}</h3>
    <p>{`The Skpr MySQL service consists of the following components:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Database Credentials`}</strong>{` - Automatically provisioned database credentials that are unique to each environment.`}</li>
      <li parentName="ul"><strong parentName="li">{`Amazon RDS Proxy`}</strong>{` - Boosts MySQL performance and availability by pooling connections.`}</li>
      <li parentName="ul"><strong parentName="li">{`Amazon Aurora`}</strong>{` - A MySQL compatible relational database built for the cloud.`}</li>
      <li parentName="ul"><strong parentName="li">{`Amazon CloudWatch`}</strong>{` - A monitoring and observability service built for DevOps engineers, developers, site reliability engineers (SREs), and IT managers.`}</li>
    </ul>
    <p><img parentName="p" {...{
        "src": "/images/posts/architecting-mysql/overview.jpg",
        "alt": "High-level view of MySQL service"
      }}></img></p>
    <p>{`This blog post will cover each of these components. `}</p>
    <h3>{`Database Credentials`}</h3>
    <p>{`The Skpr hosting platform automatically provisions a MySQL database and set of unique credentials for each environment. The lifecycle of the
database and credentials are managed using a set of custom Kubernetes controllers developed by the Skpr platform team.`}</p>
    <p>{`The provisioned database and credentials are then exposed to the application using the `}<a parentName="p" {...{
        "href": "/blog/architecting-skpr-configuration-system"
      }}>{`Skpr Configuration System`}</a>{`.`}</p>
    <p>{`Certificates are also provided to the application to allow for secure connections to the MySQL service.`}</p>
    <p>{`For more information `}<a parentName="p" {...{
        "href": "https://docs.skpr.io/mysql"
      }}>{`see our documentation`}</a>{`.`}</p>
    <h3>{`Amazon RDS Proxy`}</h3>
    <p>{`Database connections are routed by Amazon RDS Proxy, a fully managed, highly available database proxy for Amazon Relational Database
Service (RDS). Amazon RDS Proxy makes applications more scalable, more resilient to database failures, and more secure.`}</p>
    <p>{`Applications such as Drupal (PHP) do not provide native mechanisms for pooling database connections. Meaning a new connection
is made for every web request and, in the event of a high traffic scenario, consumes valuable compute resources that could be used for
more important application-focused tasks.`}</p>
    <p>{`We have seen significant performance and scalability characteristics when deploying RDS Proxy in front of our MySQL clusters.`}</p>
    <p><em parentName="p">{`The Skpr platform team intends to publish a dedicated blog post to showcase the performance and scalability improvements when deploying RDS Proxy.`}</em></p>
    <p><strong parentName="p">{`Before RDS Proxy`}</strong></p>
    <p><img parentName="p" {...{
        "src": "/images/posts/architecting-mysql/rds-proxy-before.jpg",
        "alt": "Diagram demonstrating connection flow before applying Amazon RDS Proxy"
      }}></img></p>
    <p><strong parentName="p">{`After RDS Proxy`}</strong></p>
    <p><img parentName="p" {...{
        "src": "/images/posts/architecting-mysql/rds-proxy-after.jpg",
        "alt": "Diagram demonstrating connections when after applying Amazon RDS Proxy"
      }}></img></p>
    <h3>{`Amazon Aurora`}</h3>
    <p>{`The Skpr platform is backed by `}<a parentName="p" {...{
        "href": "https://aws.amazon.com/rds/aurora"
      }}>{`Amazon Aurora`}</a>{`, a MySQL-compatible relational database solution built to run natively on the AWS cloud.`}</p>
    <p>{`Skpr MySQL clusters consist of at least 2 MySQL instances: 1 writer and 1+ readers.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Writer`}</strong>{` - The primary MySQL cluster instance that the application will connect to perform a database operation.`}</li>
      <li parentName="ul"><strong parentName="li">{`Reader`}</strong>{` - One or more MySQL cluster instances can respond to "read-only" database requests, e.g. Select Statement. In a failure, a `}<strong parentName="li">{`reader`}</strong>{` will be promoted to the `}<strong parentName="li">{`writer`}</strong>{`.`}</li>
    </ul>
    <p><img parentName="p" {...{
        "src": "/images/posts/architecting-mysql/aurora.jpg",
        "alt": "Screenshot of CloudWatch"
      }}></img></p>
    <p>{`Both of the above instance types can be connected to through a consistent, load-balanced endpoint. Both of these endpoints are available to developers through the `}<a parentName="p" {...{
        "href": "/blog/architecting-skpr-configuration-system"
      }}>{`Skpr Configuration System`}</a>{`.`}</p>
    <p>{`We found this architecture advantageous since we can utilize the `}<strong parentName="p">{`reader`}</strong>{` instances for "out of band" cluster operations such as backups, metrics gathering etc., without impacting the main `}<strong parentName="p">{`writer`}</strong>{` instance.`}</p>
    <h3>{`Amazon CloudWatch`}</h3>
    <p>{`The Skpr hosting platform provides a detailed dashboard outlining key metrics used when debugging performance issues.`}</p>
    <p>{`Key information surfaced by the dashboard includes:`}</p>
    <ul>
      <li parentName="ul">{`Resource utilisation (CPU / Memory)`}</li>
      <li parentName="ul">{`Query type breakdown (Select / Insert / Update / Delete)`}</li>
      <li parentName="ul">{`Query latency by type`}</li>
      <li parentName="ul">{`Slow queries`}</li>
    </ul>
    <p><img parentName="p" {...{
        "src": "/images/posts/architecting-mysql/cloudwatch.jpg",
        "alt": "Screenshot of CloudWatch"
      }}></img></p>
    <h3>{`Supports Custom Configurations`}</h3>
    <p>{`The Skpr hosting platform comes by default with preconfigured prod and non-production MySQL services operating in multi-tenant mode.`}</p>
    <p>{`We can also spin up dedicated MySQL services with a custom configuration for projects with specific requirements.`}</p>
    <h3>{`Roadmap`}</h3>
    <p><strong parentName="p">{`Monitoring`}</strong></p>
    <p>{`The Skpr platform team is always looking for ways to improve our monitoring and insights into the service and continue improving on the CloudWatch Dashboard.`}</p>
    <p><strong parentName="p">{`Serverless v2`}</strong></p>
    <p>{`The Skpr platform team watches the preview of `}<a parentName="p" {...{
        "href": "https://aws.amazon.com/about-aws/whats-new/2020/12/introducing-the-next-version-of-amazon-aurora-serverless-in-preview"
      }}>{`Aurora Serverless v2`}</a>{` very closely.`}</p>
    <p>{`We were very fond of Serverless v1 and its vertical autoscaling features. However, it doesn't offer multi-az support, which means it is not viable for production workloads. Serverless v2 promises to solve this shortcoming. `}</p>
    <h3>{`For More Information`}</h3>
    <p>{`Contact us today if you have any questions, `}<a parentName="p" {...{
        "href": "#request-a-demo"
      }}>{`We'd love to hear from you`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      